import { useState } from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { sidebarData } from "../utils";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";

const drawerWidth = 190;

function Sidebar() {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          display: { xs: "block", sm: "none" },
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor: "white",
          padding: "2px 0px",
        }}
      >
        <Toolbar>
          <IconButton
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{
              mr: 2,
              display: { sm: "none" },
              color: "#1976d2",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <MenuIcon fontSize="large" />

            <Typography
              component="div"
              sx={{ paddingRight: "25%", fontSize: "24px", lineHeight: "25px" }}
            >
              Omer Shavit
              <Typography
                sx={{ fontSize: "14px", color: "black", fontWeight: "300" }}
              >
                Full Stack Developer
              </Typography>
            </Typography>
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        transitionDuration={{ enter: 200, exit: 100 }}
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
          boxShadow: 3,
        }}
        variant="temporary"
        anchor="left"
      >
        <List sx={{ padding: "0px" }}>
          {sidebarData.map((menuItem) => (
            <ListItem key={menuItem.title} disablePadding>
              <ListItemButton href={menuItem.link}>
                <ListItemIcon>{menuItem.icon}</ListItemIcon>
                <ListItemText primary={menuItem.title} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Drawer
        sx={{
          display: { xs: "none", sm: "block" },
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
          boxShadow: 3,
        }}
        variant="permanent"
        anchor="left"
      >
        <div style={{ padding: "30px" }}>
          <Typography
            sx={{
              fontSize: "44px",
              fontWeight: "300",
              lineHeight: "40px",
              color: "#1976d2",
            }}
          >
            Omer Shavit
          </Typography>
          <Typography sx={{ fontSize: "14px" }}>
            Full Stack Developer
          </Typography>
        </div>
        <Divider />
        <List sx={{ padding: "0px" }}>
          {sidebarData.map((menuItem) => (
            <ListItem key={menuItem.title} disablePadding>
              <ListItemButton href={menuItem.link}>
                <ListItemIcon>{menuItem.icon}</ListItemIcon>
                <ListItemText primary={menuItem.title} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </>
  );
}

export default Sidebar;
