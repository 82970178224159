import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import { Divider } from "@mui/material";
import Grid from "@mui/material/Grid";

function Education() {
  return (
    <Grid container>
      <Grid item sm={12} md={6} textAlign={"center"}>
        <Card
          sx={{
            padding: "10px",
            margin: { xs: "20px 30px", sm: "50px 30px" },
            height: "200px",
          }}
        >
          <Typography variant="h5" component="div">
            Blich High School
          </Typography>
          <Typography
            gutterBottom
            sx={{ fontWeight: "400", fontSize: "16px" }}
            component="div"
            color="primary"
          >
            High School Diploma, 2009-2013
          </Typography>
          <Divider />
          <Typography align="left" component={"div"}>
            <ul>
              <li>Computer Science - 5 units</li>
              <li>
                Web App Project using HTML, CSS, Javascript and ASP.NET - 5
                units
              </li>
            </ul>
          </Typography>
        </Card>
      </Grid>
      <Grid item sm={12} md={6} textAlign={"center"}>
        <Card
          sx={{
            padding: "10px",
            margin: { xs: "20px 30px", sm: "50px 30px" },
            height: "200px",
          }}
        >
          <Typography variant="h5" component="div">
            Sela College
          </Typography>
          <Typography
            gutterBottom
            sx={{ fontWeight: "400", fontSize: "16px" }}
            component="div"
            color="primary"
          >
            Associate Degree, 2019-2021
          </Typography>
          <Divider />
          <Typography align="left" component={"div"}>
            <ul>
              <li>
                Software Development and QA with a focus on Full Stack
                Development
              </li>
            </ul>
          </Typography>
        </Card>
      </Grid>
    </Grid>
  );
}

export default Education;
