import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import { Box, Divider } from "@mui/material";
import Grid from "@mui/material/Grid";
import javascriptlogo from "../assets/javascript.png";
import mobxlogo from "../assets/mobx.png";
import muilogo from "../assets/mui.png";
import csslogo from "../assets/css.png";
import htmllogo from "../assets/html.png";
import mongodblogo from "../assets/mongodb.png";
import expresslogo from "../assets/express.png";
import nodejslogo from "../assets/nodejs.png";
import nextjslogo from "../assets/nextjs.png";
import reactlogo from "../assets/react.png";
import sasslogo from "../assets/sass.png";
import styledlogo from "../assets/styled-components.png";
import typescriptlogo from "../assets/typescript.png";
import wordpresslogo from "../assets/wordpress.png";
import sequelizelogo from "../assets/sequelize.jpg";
import mysqllogo from "../assets/mysql.png";
import chromelogo from "../assets/chrome.png";
import gitlogo from "../assets/git.png";
import jenkinslogo from "../assets/jenkins.png";
import jiralogo from "../assets/jira.png";
import swaggerlogo from "../assets/swagger.png";
import elementorlogo from "../assets/elementor.png";
import testcafelogo from "../assets/testcafe.png";
import mochalogo from "../assets/mocha.png";
import cicdlogo from "../assets/cicd.png";
import jestlogo from "../assets/jest.png";
import cypresslogo from "../assets/cypress.jpg";
import tailwindlogo from "../assets/tailwind.png";

function Skills() {
  return (
    <>
      <Card sx={{ maxWidth: "90%", margin: "20px auto" }}>
        <Box sx={{ padding: "20px 20px 0px 20px" }}>
          <Typography
            gutterBottom
            variant="h5"
            sx={{ fontWeight: "400" }}
            component="div"
            color="primary"
          >
            Client-Side
          </Typography>
          <Divider />
          <Box sx={{ flexGrow: 1, padding: "10px" }}>
            <Grid container rowGap={2}>
              <Grid item xs={6} sm={4} md={2} textAlign={"center"}>
                <img src={javascriptlogo} width={"65%"} alt="javascript-logo" />
                <Typography>Javascript</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={2} textAlign={"center"}>
                <img src={reactlogo} width={"75%"} alt="react-logo" />
                <Typography>React.js</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={2} textAlign={"center"}>
                <img src={typescriptlogo} width={"75%"} alt="typescript-logo" />
                <Typography>TypeScript</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={2} textAlign={"center"}>
                <Typography>
                  <img src={mobxlogo} width={"75%"} alt="mobx-logo" />
                </Typography>
                <Typography>MobX</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={2} textAlign={"center"}>
                <img src={htmllogo} width={"75%"} alt="html-logo" />
                <Typography>HTML</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={2} textAlign={"center"}>
                <img src={csslogo} width={"75%"} alt="css-logo" />
                <Typography>CSS</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={2} textAlign={"center"}>
                <img width={"75%"} src={styledlogo} alt="styled-logo" />
                <Typography>Styled Components</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={2} textAlign={"center"}>
                <img src={muilogo} width={"75%"} alt="mui-logo" />
                <Typography>Material UI</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={2} textAlign={"center"}>
                <img src={sasslogo} width={"75%"} alt="sass-logo" />
                <Typography>SASS</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={2} textAlign={"center"}>
                <img src={tailwindlogo} width={"75%"} alt="tailwind-logo" />
                <Typography>Tailwind</Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Card>
      <Card sx={{ maxWidth: "90%", margin: "20px auto" }}>
        <Box sx={{ padding: "20px 20px 0px 20px" }}>
          <Typography
            gutterBottom
            variant="h5"
            sx={{ fontWeight: "400" }}
            component="div"
            color="primary"
          >
            Server-Side
          </Typography>
          <Divider />
          <Box sx={{ flexGrow: 1, padding: "10px" }}>
            <Grid container rowGap={2}>
              <Grid item xs={6} sm={4} md={2} textAlign={"center"}>
                <img src={nodejslogo} width={"75%"} alt="nodejs-logo" />
                <Typography>Node.js</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={2} textAlign={"center"}>
                <img src={expresslogo} width={"75%"} alt="express-logo" />
                <Typography>Express.js</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={2} textAlign={"center"}>
                <Typography>
                  <img src={nextjslogo} width={"75%"} alt="nextjs-logo" />
                </Typography>
                <Typography>Next.js</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={2} textAlign={"center"}>
                <img src={mysqllogo} width={"75%"} alt="mysql-logo" />
                <Typography>MySQL</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={2} textAlign={"center"}>
                <img src={sequelizelogo} width={"75%"} alt="sequelize-logo" />
                <Typography>Sequelize.js</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={2} textAlign={"center"}>
                <img src={mongodblogo} width={"75%"} alt="mongodb-logo" />
                <Typography>MongoDB</Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Card>
      <Card sx={{ maxWidth: "90%", margin: "20px auto" }}>
        <Box sx={{ padding: "20px 20px 0px 20px" }}>
          <Typography
            gutterBottom
            variant="h5"
            sx={{ fontWeight: "400" }}
            component="div"
            color="primary"
          >
            Tools and Others
          </Typography>
          <Divider />
          <Box sx={{ flexGrow: 1, padding: "10px" }}>
            <Grid container rowGap={2}>
              <Grid item xs={6} sm={4} md={2} textAlign={"center"}>
                <img src={chromelogo} width={"75%"} alt="chrome-logo" />
                <Typography>Chrome Dev Tools</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={2} textAlign={"center"}>
                <img src={gitlogo} width={"75%"} alt="git-logo" />
                <Typography>Git</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={2} textAlign={"center"}>
                <Typography>
                  <img src={jenkinslogo} width={"75%"} alt="jenkins-logo" />
                </Typography>
                <Typography>Jenkins</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={2} textAlign={"center"}>
                <img src={cicdlogo} width={"75%"} alt="cicd-logo" />
                <Typography>CI/CD pipelines</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={2} textAlign={"center"}>
                <img src={jiralogo} width={"75%"} alt="jira-logo" />
                <Typography>Jira</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={2} textAlign={"center"}>
                <img src={swaggerlogo} width={"75%"} alt="swagger-logo" />
                <Typography>Swagger</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={2} textAlign={"center"}>
                <img src={wordpresslogo} width={"75%"} alt="wordpress-logo" />
                <Typography>WordPress</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={2} textAlign={"center"}>
                <img src={elementorlogo} width={"75%"} alt="elementor-logo" />
                <Typography>Elementor</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={2} textAlign={"center"}>
                <img src={testcafelogo} width={"75%"} alt="testcafe-logo" />
                <Typography>TestCafe</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={2} textAlign={"center"}>
                <img src={mochalogo} width={"75%"} alt="mocha-logo" />
                <Typography>Mocha</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={2} textAlign={"center"}>
                <img src={jestlogo} width={"75%"} alt="jest-logo" />
                <Typography>Jest</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={2} textAlign={"center"}>
                <img src={cypresslogo} width={"75%"} alt="cypress-logo" />
                <Typography>Cypress</Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Card>
    </>
  );
}

export default Skills;
