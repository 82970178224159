import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Page from "./Page";
import Sidebar from "./Sidebar";

function Container() {
  return (
    <>
      <Box sx={{ display: "flex", backgroundColor: "#F6F1F1" }}>
        <CssBaseline />
        <Sidebar />
        <Page />
      </Box>
    </>
  );
}

export default Container;
