import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import Scroll from "./Scroll";
import { pages } from "../utils";

function Page() {
  return (
    <Box>
      <Scroll showBelow={250} />
      {pages.map((page) => (
        <>
          <AppBar
            id={page.name.toLowerCase()}
            key={page.name}
            position="static"
            sx={{
              width: `100%`,
              boxShadow: 0,
              marginTop: {
                xs: page.name === "About" ? "65px" : "0px",
                sm: "0px",
              },
            }}
          >
            <Toolbar sx={{ padding: "20px" }}>
              <Typography
                key={page.name}
                variant="h3"
                sx={{ fontWeight: 300, fontSize: "2.5rem" }}
                noWrap
                component="div"
              >
                {page.name.toUpperCase()}
              </Typography>
            </Toolbar>
          </AppBar>
          <page.component />
        </>
      ))}
    </Box>
  );
}

export default Page;
