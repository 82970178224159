import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { contactData } from "../utils";

function Contact() {
  return (
    <>
      <List sx={{ padding: "0px" }}>
        {contactData.map((contact) => (
          <ListItem key={contact.link} disablePadding>
            <ListItemButton href={contact.link}>
              <ListItemIcon>{contact.icon}</ListItemIcon>
              <ListItemText primary={contact.title} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <br />
    </>
  );
}

export default Contact;
