import { useState, useEffect } from "react";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import IconButton from "@mui/material/IconButton";

function Scroll({ showBelow }: { showBelow: number }) {
  const [show, setShow] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > showBelow) {
      setShow(true);
    } else {
      setShow(false);
    }
  };

  useEffect(() => {
    if (showBelow) {
      window.addEventListener("scroll", handleScroll);
    }
  }, []);

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      {show && (
        <IconButton
          onClick={handleClick}
          sx={{
            zIndex: 2,
            position: "fixed",
            bottom: "2vh",
            backgroundColor: "#BDCDD6",
            color: "#6096B4",
            "&:hover, &.Mui-focusVisible": {
              transition: "0.3s",
              color: "#6096B4",
              backgroundColor: "#93BFCF",
            },
            right: { xs: "4%", sm: "2%" },
          }}
        >
          <ExpandLessIcon />
        </IconButton>
      )}
    </>
  );
}

export default Scroll;
