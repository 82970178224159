import "./App.css";
import Container from "./components/Container";

function App() {
  return (
    <>
      <Container />
    </>
  );
}

export default App;
