import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import profile from "../assets/profile.png";

function About() {
  return (
    <>
      <Typography
        align="center"
        sx={{ display: { xs: "block", sm: "none" }, marginTop: "20px" }}
      >
        <img src={profile} alt="profile" width={"40%"} />
      </Typography>
      <Typography
        color="primary"
        sx={{
          borderLeft: "4px solid #1976d2",
          padding: "10px",
          fontSize: "26px",
          margin: { xs: "20px 32px 0px 32px", sm: "20px 0px 0px 32px" },
        }}
      >
        Results-driven Full Stack Developer with a passion for a pixel perfect
        UI design, collaboration, innovation and efficiency.
      </Typography>
      <Box sx={{ p: 4 }}>
        <Typography
          sx={{
            fontSize: { xs: "20px", sm: "26px" },
            fontWeight: 300,
          }}
          paragraph
        >
          <Typography
            align="center"
            sx={{ float: "right", display: { xs: "none", sm: "block" } }}
          >
            <img src={profile} alt="profile" width={"80%"} />
          </Typography>
          Experienced Full Stack Developer with proficiency in React and a
          strong background in both front-end and back-end technologies.
          <br />
          Skilled in delivering innovative solutions both independently and as
          part of a team. Known for strong problem-solving skills and being a
          team player.
        </Typography>
        <Typography
          sx={{ fontSize: { xs: "20px", sm: "26px" }, fontWeight: 300 }}
          paragraph
        >
          Seeking a challenging Full Stack Developer position to continue honing
          skills and delivering impactful solutions.
        </Typography>
      </Box>
    </>
  );
}

export default About;
