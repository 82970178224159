import PersonIcon from "@mui/icons-material/Person";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import SnippetFolderIcon from "@mui/icons-material/SnippetFolder";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import SchoolIcon from "@mui/icons-material/School";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import DescriptionIcon from "@mui/icons-material/Description";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import EmailIcon from "@mui/icons-material/Email";
import About from "../src/pages/About";
import Contact from "../src/pages/Contact";
import Education from "../src/pages/Education";
import Experience from "../src/pages/Experience";
import Projects from "../src/pages/Projects";
import Skills from "../src/pages/Skills";

export const sidebarData = [
  {
    title: "About",
    icon: <PersonIcon color="primary" fontSize="large" />,
    link: "#about",
  },
  {
    title: "Experience",
    icon: <QueryStatsIcon color="primary" fontSize="large" />,
    link: "#experience",
  },
  {
    title: "Skills",
    icon: <EqualizerIcon color="primary" fontSize="large" />,
    link: "#skills",
  },
  {
    title: "Education",
    icon: <SchoolIcon color="primary" fontSize="large" />,
    link: "#education",
  },
  // {
  //   title: "Projects",
  //   icon: <SnippetFolderIcon color="primary" fontSize="large" />,
  //   link: "#projects",
  // },
  {
    title: "Contact",
    icon: <ContactMailIcon color="primary" fontSize="large" />,
    link: "#contact",
  },
  {
    title: "Resume",
    icon: <DescriptionIcon color="primary" fontSize="large" />,
    link: "https://drive.google.com/file/d/1mSJUAoUSkGnWQeB-3BeWjEFYEP0u-9c8/view?usp=sharing",
  },
];

export const pages = [
  { component: About, name: "About" },
  { component: Experience, name: "Experience" },
  { component: Skills, name: "Skills" },
  { component: Education, name: "Education" },
  // { component: Projects, name: "Projects" },
  { component: Contact, name: "Contact" },
];

export const contactData = [
  {
    title: "omersh468@gmail.com",
    icon: <EmailIcon color="primary" fontSize="large" />,
    link: "mailto:omersh468@gmail.com",
  },
  {
    title: "linkedin.com/in/omer-shavit",
    icon: <LinkedInIcon color="primary" fontSize="large" />,
    link: "https://www.linkedin.com/in/omer-shavit/",
  },
  {
    title: "github.com/omershav",
    icon: <GitHubIcon color="primary" fontSize="large" />,
    link: "https://github.com/omershav",
  },
];
