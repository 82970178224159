import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Divider } from "@mui/material";

function Experience() {
  return (
    <>
      <Card sx={{ maxWidth: "75%", margin: "20px auto" }}>
        <CardContent>
          <Typography
            gutterBottom
            variant="h6"
            sx={{ fontWeight: "400" }}
            component="div"
            color="primary"
          >
            The Vets, Full Stack Developer, 2022-2024
          </Typography>
          <Divider />
          <Typography sx={{ padding: "10px 0px" }} component={"div"}>
            <ul>
              <li>
                Spearheaded the development and maintenance of an internal web
                application from scratch using{" "}
                <span style={{ fontWeight: 700 }}>React</span> and{" "}
                <span style={{ fontWeight: 700 }}>Node.js</span>, resulting in a
                streamlined data management system for client data, reports,
                dynamic dashboards, tools, configurations, and documents.
              </li>
              <li>
                Created and implemented{" "}
                <span style={{ fontWeight: 700 }}>RESTful API</span> services
                with <span style={{ fontWeight: 700 }}>Node.js</span>,{" "}
                <span style={{ fontWeight: 700 }}>Sequelize.js</span>, and{" "}
                <span style={{ fontWeight: 700 }}>Swagger</span> for CRUD
                operations, adhering to{" "}
                <span style={{ fontWeight: 700 }}>
                  microservices architecture
                </span>{" "}
                principles.
              </li>
              <li>
                Implemented <span style={{ fontWeight: 700 }}>unit tests</span>{" "}
                and <span style={{ fontWeight: 700 }}>end-to-end (e2e)</span>{" "}
                tests to ensure robust front-end and back-end testing.
              </li>
              <li>
                Led the development and maintenance of the company's website
                using <span style={{ fontWeight: 700 }}>Node.js</span>,{" "}
                <span style={{ fontWeight: 700 }}>WordPress</span>, and{" "}
                <span style={{ fontWeight: 700 }}>JavaScript</span>, introducing
                new initiatives, features, and plugins, and integrating
                third-party APIs and custom services to significantly enhance
                functionality, resulting in a{" "}
                <span style={{ fontWeight: 700 }}>
                  30% increase in user engagement
                </span>{" "}
                and a{" "}
                <span style={{ fontWeight: 700 }}>
                  25% boost in site performance
                </span>
                .
              </li>
              <li>
                Worked collaboratively with{" "}
                <span style={{ fontWeight: 700 }}>SEO</span>, marketing, and{" "}
                <span style={{ fontWeight: 700 }}>UX/UI</span> design teams to
                implement{" "}
                <span style={{ fontWeight: 700 }}>website optimizations</span>{" "}
                and track performance metrics, ensuring alignment with digital
                marketing strategies, resulting in a 25% increase in organic
                traffic.
              </li>
            </ul>
          </Typography>
          <Typography
            gutterBottom
            variant="h6"
            sx={{ fontWeight: "400" }}
            component="div"
            color="primary"
          >
            Browsi, Frontend Developer, 2021-2022
          </Typography>
          <Divider />
          <Typography sx={{ padding: "10px 0px" }} component={"div"}>
            <ul>
              <li>
                Developed and optimized features using{" "}
                <span style={{ fontWeight: 700 }}>Angular</span> for an internal
                web application, ensuring high performance, responsiveness, and
                scalability while contributing to innovative and dynamic
                solutions.
              </li>
              <li>
                Developed client scripts using{" "}
                <span style={{ fontWeight: 700 }}>
                  state-oriented and asynchronous
                </span>{" "}
                programming techniques, optimizing the product across multiple
                sites. Enhanced functionality and user experience through{" "}
                <span style={{ fontWeight: 700 }}>DOM</span> manipulation with{" "}
                <span style={{ fontWeight: 700 }}>JavaScript</span>,{" "}
                <span style={{ fontWeight: 700 }}>HTML</span>, and{" "}
                <span style={{ fontWeight: 700 }}>CSS</span>.
              </li>
              <li>
                Developed complex{" "}
                <span style={{ fontWeight: 700 }}>
                  client and server automations
                </span>{" "}
                in TS and JS including API tests, Angular applications, static
                pages and listening to page and network events, while following
                the standards of{" "}
                <span style={{ fontWeight: 700 }}>
                  functional and object-oriented
                </span>{" "}
                programming.
              </li>
              <li>
                Developed infrastructure and tools for client and server
                automations with{" "}
                <span style={{ fontWeight: 700 }}>JavaScript</span>,{" "}
                <span style={{ fontWeight: 700 }}>TypeScript</span>, and{" "}
                <span style={{ fontWeight: 700 }}>Node.js</span>, leveraging
                testing platforms like{" "}
                <span style={{ fontWeight: 700 }}>Mocha</span> and{" "}
                <span style={{ fontWeight: 700 }}>TestCafe</span>. Streamlined
                the automation process,{" "}
                <span style={{ fontWeight: 700 }}>
                  reducing test execution time by 25%
                </span>
                .
              </li>
              <li>
                Worked with <span style={{ fontWeight: 700 }}>CI/CD tools</span>{" "}
                and technologies such as{" "}
                <span style={{ fontWeight: 700 }}>Git</span>,{" "}
                <span style={{ fontWeight: 700 }}>Bitbucket</span>,{" "}
                <span style={{ fontWeight: 700 }}>Docker</span>, and{" "}
                <span style={{ fontWeight: 700 }}>Jenkins</span> on{" "}
                <span style={{ fontWeight: 700 }}>Linux</span> environment,
                ensuring efficient collaboration and deployment processes.
              </li>
            </ul>
          </Typography>
        </CardContent>
      </Card>
      <Card sx={{ maxWidth: "75%", margin: "20px auto" }}>
        <CardContent>
          <Typography
            gutterBottom
            variant="h6"
            sx={{ fontWeight: "400" }}
            component="div"
            color="primary"
          >
            Tikshuv Meitav Unit, Frontend Developer and IT Support Technician,
            2013 - 2016
          </Typography>
          <Divider />
          <Typography sx={{ padding: "10px 0px" }} component={"div"}>
            <ul>
              <li>
                Built a responsive website using HTML, CSS, JavaScript and
                ASP.NET that allowed soldiers to watch how-to tutorials of
                computers & networks, together with essential technology
                utilities and Meitav unit news.
              </li>
              <li>
                Provided endpoint support to thousands of users and performed PC
                technician duties including customer service, hardware analysis,
                and troubleshooting.
              </li>
            </ul>
          </Typography>
        </CardContent>
      </Card>
    </>
  );
}

export default Experience;
